/*
 * @Description: 配置项
 * @Author: zhuangli5
 * @Date: 2022-06-29 14:37:51
 */
import { IRole } from "./interface";

export const ROLES: IRole[] = [
  {
    label: "管理员",
    value: 1,
    editable: true,
  },
  {
    label: "普通用户",
    value: 2,
    editable: false,
  },
];
