/*
 * @Author: lazyzzd
 * @Date: 2022-06-28 23:47:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-06-29 17:12:39
 * @Description: 用户信息hooks
 */
import { getUserInfo } from "@/api/user";
import { reactive } from "vue";

const useUserInfo = (() => {
  const userInfo = reactive({
    name: "",
    role: 0,
    isAdmin: false,
  });

  const refresh: () => void = async () => {
    const user = await getUserInfo();
    userInfo.role = user.role;
    userInfo.name = user.name;
    userInfo.isAdmin = user.role === 1;
  };

  return (init = false) => {
    !init && !userInfo.name && refresh();
    return { userInfo, refresh };
  };
})();

export default useUserInfo;
